.barchart-title {
	font-size: .8em;
	padding: 0 0 .5em;
    color: #919191;
    margin: 0;
	line-height: 1em;
}
figure {
	margin: 0 auto;
	max-width: 1100px;
	position: relative;
}
.graphic {
	padding-left: 30px;
}
.bar-chart-row {
	margin-bottom: 1.5em;
}
@keyframes expand {
	from {width: 0%;}
	to {width: 100%;}
}
@media screen and (min-width: 768px) {
	@keyframes expand {
		from {width: 0%;}
		to {width: calc(100% - 75px);}
	}
}
.chart {
	overflow: hidden;
	width: 0%;
	animation: expand 1.5s ease forwards;
}
.bar-chart-row + .bar-chart-row .chart {
	animation-delay: .2s;
}
.bar-chart-row + .bar-chart-row + .bar-chart-row .chart {
	animation-delay: .4s;
}
.chart-block {
	display: block;
	height: 100px;
	color: #fff;
	font-size: .75em;
	float: left;
	background-color: #334D5C;
	position: relative;
	overflow: hidden;
	opacity: 1;
	transition: opacity, .3s ease;
	cursor: pointer;
}
.chart-block:nth-of-type(2),
.legend li:nth-of-type(2):before {
	background-color: #45B29D;
}
.chart-block:nth-of-type(3),
.legend li:nth-of-type(3):before {
	background-color: #EFC94C;
}
.chart-block:nth-of-type(4),
.legend li:nth-of-type(4):before {
	background-color: #E27A3F;
}
.chart-block:nth-of-type(5),
.legend li:nth-of-type(5):before {
	background-color: #DF5A49;
}
.chart-block:nth-of-type(6),
.legend li:nth-of-type(6):before {
	background-color: #962D3E;
}
.chart-block:hover {
	opacity: .65;
}
.chart-value {
	display: block;
	line-height: 1em;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%);
}
.x-axis {
    text-align: center;
	padding: .5em 0 2em;
}

.legend {
    margin: 0 auto;
    padding: 0;
	font-size: .9em;
}
.legend li {
    display: inline-block;
    padding: .25em 1em;
    line-height: 1em;
}
.legend li:before {
    content: "";
    margin-right: .5em;
    display: inline-block;
    width: 8px;
    height: 8px;
	background-color: #334D5C;
}
@media screen and (min-width: 768px) {
	.barchart-title {
		padding: 0;
		width: 75px;
		float: left;
		line-height: 100px;
	}
	.chart-block {
		font-size: 1em;
	}
	.legend {
		width: 50%;
	}
}